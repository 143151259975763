
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiCustomDeclarationImport } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  commonBackToList,
  commonChangeDefaultDate,
  setModuleBCN,
} from "@/core/directive/function/common";

export default defineComponent({
  name: "exception-declaration-management-import-declaration-information",
  components: {},
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const formData = ref({
      id: route.params.id,
      declaration_import_no: "",
      consignee_name: "",
      declaration_category: "",
      import_date: null,
      customs_declaration_date: null,
      registration_number: "",
      exporter_name: "",
      delivery_type: "",
      carrier_name_and_flight_number: "",
      awb_number: "",
      warehouse_name: "",
      contract_number: "",
      country_of_trade: "",
      country_of_export: "",
      transit_port: "",
      import_port: "",
      packing_materials: "",
      quantity: "",
      gross_weight: "",
      net_weight: "",
      trade_terms: "",
      shipping_fee: "",
      insurance_fee: "",
      other_fees: "",
      import_nation: "",
      hscode_of_import_nation: "",
      size: "",
      color: "",
      gtin: "",
      cas: "",
      unit_price: "",
      sub_total_price: "",
      currency: "",
      country_of_origin: "",
      country_of_recipient: "",
      duties_term: "",
      submission_date: null,
      approval_date: null,
      re_submission_date: null,
      re_submission_count: "",
      customs_inspection_address: "",
      customs_inspection: 10,
      customs_inspection_date: null,
      shipment: null,
    });

    const options = ref({
      declaration_category: [],
      delivery_type: [],
      country_of_trade: [],
      country_of_export: [],
      transit_port: [],
      import_port: [],
      packing_materials: [],
      trade_terms: [],
      import_nation: [],
      size: [],
      color: [],
      currency: [],
      country_of_origin: [],
      country_of_recipient: [],
      duties_term: [],
    });

    const rules = ref({
      declaration_import_no: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      import_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      customs_declaration_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      registration_number: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      approval_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const getShowInfo = async () => {
      const { data } =
        await ApiCustomDeclarationImport.showCustomDeclarationImport({
          id: route.params.id,
        });
      if (data.code == 0) {
        formData.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getTaggingData = async () => {
      // const { data } = await ApiBase.getTaggingData({
      //   short_key: ["warehouse_type", "warehouse_taggings"],
      // });
      // if (data.code == 0) {
      //   // options.value.type = data.data.warehouse_type.items;
      //   // options.value.taggings = data.data.warehouse_taggings.items;
      // }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getShowInfo(), getTaggingData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiCustomDeclarationImport.updateCustomDeclarationImport(
            formData.value
          )
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      commonBackToList(router, "/declaration-management/import-declaration");
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getFromInfo();
    });

    return {
      t,
      commonChangeDefaultDate,
      loading,
      options,
      formData,
      formRef,
      rules,
      submitButton,
      getFromInfo,
      submit,
      backToList,
    };
  },
});
